
.about {
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include screen-from('tablet-wide') {
    display: grid;
    grid-template-columns: 28.82% auto 30.56%;
    height: 100%;
  }
}

.about__img {
  width: 100%;

  @include screen-from('tablet-wide') {
    height: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.about__content {
  margin-top: clamp(54px, 14vw, 200px);
  margin-bottom: clamp(54px, 14vw, 200px);
  height: 100%;

  @include screen-from('tablet-wide') {
    margin: 0;
    padding: 0;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    box-sizing: border-box;
  }
}

.about__title {
  font-family: $font;
  font-size: 12px;
  color: $color-black;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5px;
  font-size: clamp(12px, 3vw, 18px);

  @include screen-from('tablet-wide') {
    font-size: 12px;
  }
}

.about__text {
  font-family: $font;
  font-size: 12px;
  color: $color-gray;
  margin-top: 15px;
  line-height: 170%;
  font-size: clamp(12px, 2vw, 15px);

  @include screen-from('tablet-wide') {
    font-size: 12px;
  }

  @include screen-from('desktop') {
    font-size: 12px;
  }
}
