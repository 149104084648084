
.hamburger {
  width: 20px;
  height: 14px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger__slice {
  width: 100%;
  height: 2px;
  background-color: $color-white;
  transition: transform 0.3s, background-color 0.3s;
}

.hamburger--active .hamburger__slice {
  background-color: $color-gray;

  &:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }

  &:nth-child(2) {
    visibility: hidden;
  }

  &:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
}
