
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 0 25px;
  display: grid;
  grid-template-columns: 20px auto 20px;
  justify-items: center;
  align-items: center;
  z-index: 1;

  @include screen-from('tablet-wide') {
    grid-template-columns: 100px auto;
    justify-items: start;
    top: 60px;
    left: 60px;
    height: auto;
    width: auto;
  }
}

.header__hamburger {
  @include screen-from('tablet-wide') {
    display: none;
  }
}
.header--active .header__hamburger {
  position: relative;
  z-index: 2;
  left: 0;
  top: 0;
}

.header__nav {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  transform: translateX(-5px);
  transition: all 0.2s;
  opacity: 0;

  @include screen-from('tablet-wide') {
    visibility: visible;
    background-color: transparent;
    position: relative;
    opacity: 1;
  }
}
.header--active .header__nav {
  visibility: visible;
  display: block;
  transform: translateX(0);
  opacity: 1;
}

.header__menu {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  padding-right: 13px;

  @include screen-from('tablet-wide') {
    justify-content: flex-start;
  }
}

.header__menu-item {
  position: relative;

  &:hover {
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: $color-white;
      margin-left: auto;
      margin-right: auto;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.header__menu-link {
  color: $color-black;
  text-decoration: none;
  font-size: 12px;
  padding: 13px;

  @include screen-from('tablet-wide') {
    color: $color-white;
    font-weight: 600;
    padding: 5px 16px;
  }
}
